@import "includes";

* {
  box-sizing: border-box;
  font-family: Noto Sans, sans-serif;
  font-weight: normal;
}

.main {
  background: #ffffff;
}

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// display on md and higher
.d-middle {
  @extend .d-none;
  @extend .d-md-block;
}

// display on xs and sm
.d-small {
  @extend .d-md-none;
}

// button
.btn-notify {
  @extend .btn;
  background: #FDC501;
  border-radius: 25px;
  white-space: nowrap;
}

.block-icons {
  padding-bottom: 20px;

  .support {
    @extend %flex-center;
  }

  .support__image {
    justify-content: center;
    display: flex;
  }

  .support__text {
    font-size: 16px;
    line-height: 20px;
  }

  @include media-breakpoint-up(lg) {
    & {
      padding-bottom: 60px;
    }

    .support {
      // flex-direction: column;
    }

    .support__image {
      margin-bottom: 15px;
    }

    .image-icon {
      transform: scale(1.42);
    }

    .support__text {
      text-align: center;
    }
  }

  @include media-breakpoint-up(xl) {
    & {
      padding-bottom: 40px;
    }

    .support__text {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans-Regular.woff2") format("woff2"),
    url("./fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans-Bold.woff2") format("woff2"),
    url("./fonts/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.text-white {
  color: #ffffff;
}

.text-bold {
  font-weight: bold;
}

h1, h2, h3 {
  text-align: center;
  white-space: pre-line;
}

h1, h2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}

h3 {
  font-size: 14px;
  line-height: 19px;
}

@include media-breakpoint-up(md) {
  h1, h2 {
    font-size: 36px;
    line-height: 44px;
  }

  h3 {
    font-size: 20px;
    line-height: 27px;
  }
}

@include media-breakpoint-up(lg) {
  h1, h2 {
    padding: 0 100px;
  }

  h3 {
    padding: 0 200px;
  }
}

@include media-breakpoint-up(xl) {
  h1, h2 {
    font-size: 48px;
    line-height: 50px;
  }

  h3 {
    font-size: 24px;
    line-height: 33px;
  }
}

.block-students {
  h2, h3 {
    color: #ffffff;
  }

  h3 {
    margin-bottom: 25px;
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    img {
      transform-origin: center bottom;
      width: 995px;
      height: 312px;
      flex-shrink: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .image-wrapper img {
      margin-top: 80px;
      transform: scale(1.32);
    }

    h3 {
      margin-bottom: 35px;
    }
  }
}

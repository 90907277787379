.block-reasons {
  padding-bottom: 60px;

  h2 {
    margin-bottom: 35px;
  }

  .reason__image {
    @extend %flex-center;
    width: 100%;
    margin-bottom: 15px;
  }

  .reason__header,
  .reason__content {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }

  .reason__header {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .reason__content {
    color: #6f6f6f;
  }

  @include media-breakpoint-up(lg) {
    .reason__image {
      height: 180px;
      transform: scale(1.2);
      margin-bottom: 30px;
    }

    .reason__header {
      margin-bottom: 15px;
    }
  }

  @include media-breakpoint-up(xl) {
    .reason__image {
      height: 200px;
    }
  }
}

.block-footer {
  padding-bottom: 25px;

  .link-group {
    @extend %flex-center;
  }

  .footer-link {
    text-decoration: underline;
    font-size: 14px;
    line-height: 22px;

    &:not(:last-child) {
      margin-right: 80px;
    }
  }

  .copyright-wrapper {
    @extend %flex-center;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
  }

  .copyright {
    margin-bottom: 10px;
  }

  .address {
    color: #6f6f6f;
  }

  @include media-breakpoint-up(lg) {
    & {
      padding-top: 40px;
    }

    .footer-link {
      font-size: 20px;
      line-height: 22px;
    }

    .copyright-wrapper {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @include media-breakpoint-up(xl) {
    .footer-link {
      font-size: 24px;
      line-height: 22px;
    }

    .copyright-wrapper {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

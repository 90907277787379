.block-curriculum {
  padding-bottom: 60px;

  img {
    width: 250px;
    height: 140px;
  }

  h3 {
    margin-bottom: 20px;
  }

  %btn-colors {
    color: #ffffff;
    background-color:#42ABE9;
  }

  .btn-carousel--small {
    @extend %btn-colors;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn-carousel--small,
  .dropdown-carousel .dropdown-menu,
  .card-highlighter,
  .carousel-item img {
    width: 100%;
    max-width: 400px;
  }

  .carousel-item img {
    height: auto;
  }

  .dropdown-carousel .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }

  .btn-carousel.active {
    @extend %btn-colors;
    pointer-events: none;
  }

  .dropdown-carousel {
    @extend %flex-center;
    margin-bottom: 10px;
    width: 100%;
  }

  .carousel-indicators {
    @extend %flex-center;
    bottom: -55px;
  }

  .carousel-indicators li {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background-color: #c4c4c4;

    &.active {
      width: 11px;
      height: 11px;
      background-color: #42ABE9;
    }
  }

  .dropdown-carousel .dropdown-item:active {
    @extend .btn-carousel;
  }

  .dropdown-col {
    @extend %flex-center;
  }

  .cards-preview-small {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .card-highlighter {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #c4c4c4;
    opacity: 0.3;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .button-selector {
    @extend %flex-center;
    margin-bottom: 40px;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }

  .cards-preview {
    @extend %flex-center;
  }

  .card {
    position: relative;
    cursor: pointer;
    border-radius: 12px;

    img {
      width: 250px;
      height: 140px;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: "";
      width: 60px;
      height: 60px;
      background-image: url('/assets/images/curriculum/carousel/play-button.svg');
    }

    &:hover:after {
      background: url('/assets/images/curriculum/carousel/play-button-hover.svg');
    }
  }

  .carousel-control-next-icon {
    background: url('/assets/images/curriculum/carousel/next.svg');
    width: 35px;
    height: 54px;
  }
  .carousel-control-prev-icon {
    background: url('/assets/images/curriculum/carousel/prev.svg');
    width: 35px;
    height: 54px;
  }

  @include media-breakpoint-up(md) {
    .btn-carousel--small,
    .dropdown-carousel .dropdown-menu,
    .card-highlighter,
    .carousel-item img {
      max-width: 520px;
    }
  }

  @include media-breakpoint-up(lg) {
    & {
      padding-bottom: 70px;
    }

    h3 {
      margin-bottom: 50px;
    }
  }
}

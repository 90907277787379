.block-review {
  h2 {
    margin-bottom: 30px;
  }

  .quote-text {
    position: relative;
    padding: 0 15px;
  }

  .quote-author {
    text-align: center;
    opacity: 0.6;
  }

  .review-quote {
    position: relative;
    @extend %flex-center;
    flex-direction: column;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 25px;
  }

  blockquote {
    position: relative;
    font-style: italic;
    text-align: center;
    margin-bottom: 30px;
  }

  blockquote:before,
  blockquote:after {
    display: block;
    position: absolute;
  }

  .dino-sing {
    display: flex;
    justify-content: center;
  }

  .dino-sing img {
    transform-origin: left bottom;
  }

  @include media-breakpoint-up(lg) {
    h2 {
      margin-bottom: 50px;
    }

    .quote-text {
      font-size: 18px;
      line-height: 25px;
    }

    .quote-author {
      font-size: 16px;
      line-height: 22px;
    }

    blockquote:before {
      left: -60px;
      top: -30px;
      content: url('/assets/images/review/quote-start.svg');
    }

    blockquote:after {
      right: -60px;
      bottom: -35px;
      content: url('/assets/images/review/quote-end.svg');
    }

    .dino-sing {
      justify-content: start;
      margin-top: 30px;
    }

    .dino-sing img {
      position: absolute;
      bottom: 0;
      left: 15px;
      transform: scale(1.25);
    }

    blockquote {
      max-width: 385px;
    }
  }

  @include media-breakpoint-up(xl) {
    h2 {
      margin-bottom: 60px;
    }

    .quote-text {
      font-size: 20px;
      line-height: 27px;
    }

    .quote-author {
      font-size: 18px;
      line-height: 25px;
    }

    .dino-sing img {
      transform: scale(1.4);
    }

    blockquote {
      max-width: 596px;
    }
  }
}

#card-modal {
  .modal-header {
    padding: 0.5rem 1rem;
    border-bottom: none;
  }

  .close {
    z-index: 2;
  }

  .modal-dialog {
    max-width: 1026px;
  }

  .modal-content {
    height: 622px;
  }

  .modal-body {
    padding: 0;
    top: -30px;
  }

  iframe {
    width: 1024px;
    height: 610px;
    position: relative;
  }

  .spinner-border {
    position: absolute;
    left: 471px;
    top: 235px;
    width: 100px;
    height: 100px;
  }
}

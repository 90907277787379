.block-header {
  @extend .block;
  background-color: #42ABE9;

  h3 {
    margin-top: 10px;
    margin-bottom: 22px;
  }

  .header-area {
    position: relative;
    @extend %flex-center;
    padding-bottom: 100px;
    overflow: hidden;
  }

  .header-area__wrapper {
    @extend %flex-center;
  }

  .header-area__container {
    background: white;
    border-radius: 20px;

    // flex-direction: column;
    // @extend %flex-center;

    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding: 20px 27px;
  }

  button {
    margin-top: 17px;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 22px;
    padding: 11px 60px;
  }

  .header-images {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .header-images__left {
    position: relative;
    transform-origin: left bottom;
    left: -50px;
  }

  .header-images__right {
    position: relative;
    right: 36px;
    bottom: 8px;
    transform-origin: right bottom;
  }

  @include media-breakpoint-up(lg) {
    .header-area__container {
      max-width: 518px;
      font-size: 16px;
      line-height: 22px;
    }

    .header-area__text {
      padding: 0 60px;
    }

    .header-images__left {
      transform: scale(1.39);
      left: 15px;
    }

    .header-images__right {
      transform: scale(2);
      right: 0;
      bottom: 45px;
    }
  }

  @include media-breakpoint-up(xl) {
    .header-area__container {
      max-width: 766px;
      font-size: 20px;
      line-height: 27px;
    }

    .header-images__left {
      transform: scale(1.59);
    }

    .header-images__right {
      transform: scale(2.35);
      bottom: 28px;
    }
  }
}

@import "blocks/modal";
@import "blocks/navbar";
@import "blocks/header";
@import "blocks/curriculum";
@import "blocks/review";
@import "blocks/reasons";
@import "blocks/form";
@import "blocks/students";
@import "blocks/icons";
@import "blocks/footer";

.block {
  @extend .container-md;
  width: 100%;
  padding-top: 20px;
  position: relative;
  overflow: hidden;
}

.block-navbar {
  @extend .block;
}

.block-curriculum {
  @extend .block;
  overflow: visible;
}

.block-header {
  @extend .block;
}

.block-review {
  @extend .block;
  background-color: #FDC501;
}

.block-reasons {
  @extend .block;
}

.block-form {
  @extend .block;
  background-color: #FF606F;
}

.block-students {
  @extend .block;
  background: linear-gradient(180deg, #580E54 0%, #6D0863 100%);
}

.block-icons {
  @extend .block;
}

.block-footer {
  @extend .block;
  padding-top: 0;
  background-color: #F1F1F1;
}

@include media-breakpoint-up(lg) {
  .block {
    padding-top: 40px;
  }

  .block-footer {
    padding-top: 10px;
  }
}

.block-navbar {
  padding-bottom: 17px;

  .logo {
    display: flex;
    align-items: center;
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .btn-navbar {
    font-size: 14px;
    line-height: 19px;
    padding: 6px 10px;
  }

  @include media-breakpoint-up(md) {
    .btn-navbar {
      padding: 11px 45px;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.block-form {
  h2, h3 {
    color: #ffffff;
  }

  h3 {
    margin-bottom: 40px;
  }

  .form-wrapper,
  .image-wrapper {
    @extend %flex-center;
  }

  .form-wrapper {
    margin-bottom: 25px;
  }

  form {
    min-width: 320px;
    max-width: 708px;
    width: 80%;
    background: #ffffff;
    border-radius: 20px;
    padding: 20px 10px 45px 10px;
  }

  .info-text {
    margin-right: 10px;
  }

  .radio-group,
  .email-group {
    @extend %flex-center;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .custom-radio {
    display: inline-block;
    height: 25px;
    width: 25px;
    background-color: #FFFBE6;
    border: 2px solid #FDC501;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .custom-radio:after {
    content: "";
    position: relative;
    display: inline-block;
    left: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .form-check-label {
    @extend %flex-center;
    cursor: pointer;
    margin-bottom: 0;
  }

  .form-check-label:not(:last-child) {
    margin-right: 8px;
  }

  .custom-radio {
    order: -1;
    margin: 0 10px;
  }

  // TODO disable hover on selected radio button

  .form-check-label:hover .custom-radio {
    background-color: #FDC501;
  }

  input:checked ~ .custom-radio:after {
    background-color: #000000;
  }

  .info-text-wrapper {
    @extend %flex-center;
    margin-bottom: 5px;
  }

  label,
  .info-text {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }

  input[type="email"] {
    background: #FFFBE6;
    border: 2px solid #FDC501;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 11px 26px;
    width: 100%;
    margin-bottom: 12px;
  }

  .btn-form {
    height: 50px;
    width: 100%;
  }

  .dino-run {
    transform-origin: center bottom;
  }

  @include media-breakpoint-up(lg) {
    .form-wrapper {
      margin-bottom: 60px;
    }

    form {
      width: 588px;
    }

    form .info-text-wrapper {
      margin-bottom: 0;
      justify-content: flex-end;
    }

    .email-input-col {
      flex-grow: 1;
      padding-right: 10px;
    }

    form input[type=email] {
      margin-bottom: 0;
      min-width: 331px;
    }

    .form-group.email-group {
      justify-content: space-between;
      padding: 0 40px;
    }

    .dino-run {
      transform: scale(1.22);
    }

    .btn-form {
      width: auto;
      padding: 11px 30px;
    }
  }

  @include media-breakpoint-up(xl) {
    .form-wrapper {
      margin-bottom: 90px;
    }

    form {
      width: 708px;
    }

    form input[type=email] {
      min-width: 386px;
    }

    .dino-run {
      transform: scale(1.47);
    }

    .btn-form {
      padding: 11px 60px;
    }
  }
}
